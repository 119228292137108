import React, { useState } from "react";
import Modal from "./Modal";
import ModalMessage from "./ModalMessage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductsComponent = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [t] = useTranslation("global");

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const openModalMessages = () => {
    setShowModalMessage(true);
  };

  const closeModalMessage = () => {
    setShowModalMessage(false);
  };

  return (
    <div className="container mx-auto ">
      <section className="flex flex-col items-center bg-white">
        <div className="mt-10 grid max-w-md grid-cols-1 gap-6 px-2 sm:max-w-lg sm:px-20 md:max-w-screen-xl md:grid-cols-2 md:px-10 lg:grid-cols-3 lg:gap-8">
          {/* <!--end property--> */}
          {props.products.slice(0, props.numProducts).map((product) => (
            <article
              key={product.id}
              className="mb-4 overflow-hidden rounded-xl border text-gray-700 shadow-md duration-500 ease-in-out hover:shadow-xl p-4"
            >
              <Link to={`/product-details/${product.id}`} key={product.id}>
                <div className="relative mb-4 rounded-2xl">
                  <div className="flex divide-y top-2 absolute z-10 w-full gap-2">
                    <div className="bg-gray-50 text-accent px-3 py-1 rounded-3xl ml-2">
                      <span className="font-normal text-sm capitalize">
                        {product.status}
                      </span>
                    </div>
                    <div className="bg-gray-50 text-accent px-3 py-1 rounded-3xl ">
                      <span className="font-normal text-sm capitalize">
                        {product.product_type.name}
                      </span>
                    </div>
                  </div>

                  <div className="object-cover">
                    {product.images && product.images.length > 0 ? (
                      <img
                        src={`https://api.essenlux.ma/storage/${product.images[0]}`}
                        className="rounded-2xl min-h-72 w-full h-[300px]"
                        alt={product.name || "Product Image"}
                      />
                    ) : (
                      <div className="rounded-2xl min-h-72 flex items-center justify-center bg-gray-200">
                        <span className="text-gray-500">
                          No Image Available
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="flex divide-y -bottom-1 absolute z-10 w-full">
                    <div className="bg-accent text-white px-3 py-1 rounded-3xl ">
                      <span className="font-normal text-sm capitalize">
                        {product.price} DHS
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="p-4">
                <div className="pb-6 flex justify-between items-center gap-5 text-accent">
                  <a
                    href="/"
                    className="text-lg font-medium duration-500 ease-in-out capitalize"
                  >
                    {product.name}
                  </a>
                  <a
                    href="/"
                    className="text-lg font-medium duration-500 ease-in-out capitalize"
                  >
                    {product.address}
                  </a>
                </div>

                <ul className="box-border flex list-none items-center border-t border-b border-solid border-gray-200 px-0 py-6 flex-wrap justify-between">
                  <li className="mr-4 flex items-center text-left">
                    <i className="mr-2 text-2xl text-accent">
                      {/* <!-- uil:compress-arrows --> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-5 w-5"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M10.38 13.08A1 1 0 0 0 10 13H6a1 1 0 0 0 0 2h1.59l-5.3 5.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0L9 16.41V18a1 1 0 0 0 2 0v-4a1 1 0 0 0-.08-.38a1 1 0 0 0-.54-.54ZM10 5a1 1 0 0 0-1 1v1.59l-5.29-5.3a1 1 0 0 0-1.42 1.42L7.59 9H6a1 1 0 0 0 0 2h4a1 1 0 0 0 .38-.08a1 1 0 0 0 .54-.54A1 1 0 0 0 11 10V6a1 1 0 0 0-1-1Zm3.62 5.92A1 1 0 0 0 14 11h4a1 1 0 0 0 0-2h-1.59l5.3-5.29a1 1 0 1 0-1.42-1.42L15 7.59V6a1 1 0 0 0-2 0v4a1 1 0 0 0 .08.38a1 1 0 0 0 .54.54ZM16.41 15H18a1 1 0 0 0 0-2h-4a1 1 0 0 0-.38.08a1 1 0 0 0-.54.54A1 1 0 0 0 13 14v4a1 1 0 0 0 2 0v-1.59l5.29 5.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"
                        />
                      </svg>
                    </i>
                    <span className="text-sm text-gray-400">
                      {" "}
                      {product.surface}m<sup>2</sup>
                    </span>
                  </li>

                  <li className="mr-4 flex items-center text-left">
                    <i className="mr-2 text-2xl text-accent">
                      {/* <!-- ic:outline-king-bed --> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-5 w-5"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M22 12c0-1.1-.9-2-2-2V7c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v3c-1.1 0-2 .9-2 2v5h1.33L4 19h1l.67-2h12.67l.66 2h1l.67-2H22v-5zm-4-2h-5V7h5v3zM6 7h5v3H6V7zm-2 5h16v3H4v-3z"
                        />
                      </svg>
                    </i>
                    <span className="text-sm text-gray-400 flex flex-row">
                      <span className="hidden md:block lg:block">
                        {t("Chambres")}:{" "}
                      </span>
                      <span>{product.rooms_number}</span>{" "}
                    </span>
                  </li>

                  <li className="flex items-center text-left">
                    <i className="mr-2 text-2xl text-accent">
                      {/* <!-- bx:bath --> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        className="h-5 w-5"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M21 10H7V7c0-1.103.897-2 2-2s2 .897 2 2h2c0-2.206-1.794-4-4-4S5 4.794 5 7v3H3a1 1 0 0 0-1 1v2c0 2.606 1.674 4.823 4 5.65V22h2v-3h8v3h2v-3.35c2.326-.827 4-3.044 4-5.65v-2a1 1 0 0 0-1-1zm-1 3c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4v-1h16v1z"
                        />
                      </svg>
                    </i>
                    <span className="text-sm text-gray-400 flex flex-row">
                      <span className="hidden md:block lg:block">
                        {t("Sale de bain")}:{" "}
                      </span>
                      <span>{product.douche}</span>{" "}
                    </span>
                  </li>
                </ul>
                <div className="m-0 flex items-center justify-between px-0 pt-6 pb-0">
                  <button
                    onClick={openModalMessages}
                    className="flex flex-col items-center text-center"
                  >
                    <i className="ri-mail-line text-accent"></i>
                    <span className="text-sm text-gray-400">Email</span>
                  </button>
                  <button
                    onClick={openModal}
                    className="flex flex-col items-center text-end"
                  >
                    <i className="ri-whatsapp-line text-accent"></i>
                    <span className="text-sm text-gray-400">Whatsapp</span>
                  </button>
                </div>
              </div>
            </article>
          ))}
        </div>
        <Modal isOpen={showModal} onClose={closeModal} />
        <ModalMessage isOpen={showModalMessage} onClose={closeModalMessage} />
      </section>
    </div>
  );
};

export default ProductsComponent;
