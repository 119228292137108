import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { transport } from "../utils/TransportApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import contact from "../assets/contactSection.webp";
const Contact = () => {
  const [t] = useTranslation("global");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      demande: "",
      message: "",
    },
  });
  const [phone, setPhone] = useState();

  const onSubmit = async (data) => {
    try {
      data.telephone = phone;
      await transport.post("/contact", data).then((res) => {
        toast.success("Form submitted successfully!");
        reset();
        setPhone("");
      });
    } catch (error) {
      toast.error("Error submitting form");
      console.error("Error submitting form:", error);
    }
  };
  return (
    <div className="w-full">
      <div className="relative h-64 m-8 overflow-hidden rounded-lg">
        <div className="absolute z-30 flex w-full h-full">
          <div className="relative z-30 w-full px-6 py-8 text-[#CBA660] md:py-10 md:w-1/2 mt-16 uppercase font-semibold">
            <h2 className="text-xl text-center ms:text-3xl md:text-3xl lg:text-3xl xl:text-3xl">
              {t("Contact us")}
            </h2>
          </div>
        </div>
        <div className="absolute top-0 right-0 w-full h-full">
          <img
            alt="Snowy mountain lake"
            className="object-cover w-full h-full"
            src={contact}
          />
        </div>
      </div>
      <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center lg:gap-28 gap-3 py-10">
        <div className="mx-auto mt-4 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h3 className="border-l border-primary pl-6 font-semibold text-gray-900">
              {t("Address")}
            </h3>
            <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
              <p>{t("downtown")}, Ouled Teima </p>
            </address>
          </div>

          <div>
            <h3 className="border-l border-primary pl-6 font-semibold text-gray-900">
              {t("Phone")}
            </h3>
            <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
              <p>+212 661 382 615 (Abdallah)</p>
            </address>
          </div>

          <div>
            <h3 className="border-l border-primary pl-6 font-semibold text-gray-900">
              Email
            </h3>
            <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
              <p>contact.essenlux@gmail.com</p>
            </address>
          </div>
        </div>
      </div>

      <div className="container mx-auto flex flex-row justify-center items-center gap-4 py-4 ">
        <div className="bg-[#fff] border p-4 rounded-md shadow-md">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  {t("First Name")}
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                  id="grid-first-name"
                  type="text"
                  placeholder="Prénom"
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <span className="text-red-500">This field is required</span>
                )}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  {t("Last Name")}
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 "
                  id="grid-last-name"
                  type="text"
                  placeholder="Nom"
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-email"
                >
                  Email
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 "
                  id="grid-email"
                  type="email"
                  placeholder="Email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-telephone"
                >
                  {t("Phone")}
                </label>
                <PhoneInput
                  placeholder="Entrer votre numéro de téléphone"
                  defaultCountry="MA"
                  value={phone}
                  required
                  onChange={setPhone}
                  style={{
                    borderRadius: 3,
                    height: "2.5rem",
                    // padding: 10,
                    lineHeight: 2,
                    paddingLeft: 12,
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 "
                  // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
                {errors.telephone && (
                  <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-2">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-state"
                >
                  {t("Request")}
                </label>
                <div className="relative">
                  <select
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 "
                    id="grid-state"
                    {...register("demande", { required: true })}
                    placeholder="Information request"
                  >
                    <option>{t("Information request")}</option>
                    <option>{t("I want to buy/rent a property")}</option>
                    <option>{t("I want to sell/rent my property")}</option>
                    <option>{t("Other")}</option>
                  </select>
                  {errors.demande && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Message
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 "
                  id="grid-text"
                  type="text"
                  placeholder="Taper votre message içi..."
                  rows={3}
                  {...register("message", { required: false })}
                />
                {errors.message && (
                  <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                )}
              </div>
            </div>
            <button
              type="submit"
              className=" hover:bg-[#22213D] bg-[#cba660]  text-white font-normal rounded-md py-2 px-8 "
            >
              {t("Submit")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
