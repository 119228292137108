import React from "react";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa6";

const Footer = () => {
  const [t] = useTranslation("global");
  return (
    <footer className="relative bg-[#22213D] pt-8 pb-6 font-light text-white sm:text-lg">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-bold text-[#cba660]">
              {t("Restons en contact")} !
            </h4>
            <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
              {t("Retrouvez-nous")}
            </h5>
            
            <div className="mt-2 lg:mb-0 mb-6 flex flex-row">
              <a
                href="https://www.youtube.com/@essenlux"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2"
              >
                <button
                  className="bg-[#cba660] text-lightBlue-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <FaYoutube size={20} />
                </button>
              </a>
              <a
                href="https://web.facebook.com/ESSENLUX1"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2"
              >
                <button
                  className="bg-[#cba660] flex text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <FaFacebookF size={20} />
                </button>
              </a>
              <a
                href="https://www.instagram.com/essenlux.ma?igsh=MWRicjVrYTVhMWRlMg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="bg-[#cba660] flex text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <FaInstagram size={20} />
                </button>
              </a>
              <a
                href="https://www.tiktok.com/@essenlux.ma?_t=8n05Q8oeNmf&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="bg-[#cba660] flex text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <FaTiktok size={20} />
                </button>
              </a>
            </div>
            <h1 className="text-accent font-bold mt-4">{t("Nos contacts")}</h1>
            <div className="grid grid-cols-1 text-white gap-1">
              <div className="grid grid-cols-2 gap-2 font-light">
                <span className="flex justify-start gap-1 items-center">
                  +212 661 382 615
                </span>
                <span>+212 661 376 885</span>
                <span>+212 661 485 247</span>
                <span>+212 809 897 511</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-[#cba660] text-sm font-semibold mb-2">
                  {t("Liens utiles")}
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/"
                    >
                      {t("Home")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/about-us"
                    >
                      {t("À propos de nous")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/location"
                    >
                      {t("Rent")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/projects"
                    >
                      {t("Projects")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-[#cba660] text-sm font-semibold mb-2">
                  {t("Autres ressources")}
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/politiqueConfidantialite"
                    >
                      {t("Privacy Policy")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/honoraires"
                    >
                      {t("Honoraires")}
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                      href="/contact"
                    >
                      {t("Contactez nous")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-blueGray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1">
              Copyright © <span id="get-current-year">2024</span>
              <a
                href="/"
                className="text-blueGray-500 hover:text-gray-800"
                target="_blank"
              />{" "}
              Essen Lux.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
