import React, { useContext, useEffect, useRef, useState } from "react";
import "./Testimonials.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { transport } from "../../utils/TransportApi";
import { ProductsContext } from "../../utils/context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

import { Zoom } from "react-awesome-reveal";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const { selectedLanguage } = useContext(ProductsContext);
  const carouselRef = useRef(null);
  const [t] = useTranslation("global");

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await transport.get("/testimonials");
      setTestimonials(response.data);
    } catch (error) {
      toast.success(error.response.data.message);
    }
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="py-12 md:py-24">
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mb-10 sm:mb-14 flex flex-col items-center sm:flex-row sm:items-center sm:justify-between gap-6">
        <Zoom direction="in">
          <h2 className="text-3xl md:text-3xl font-bold text-accent text-center sm:text-left uppercase">
            {t("Témoignages")}
          </h2>
        </Zoom>
        <Zoom direction="in">
          <div className="flex items-center gap-4 sm:gap-8">
            <button
              id="slider-button-left"
              className="swiper-button-prev group flex justify-center items-center border border-solid border-[#cba660] w-10 h-10 md:w-12 md:h-12 transition-all duration-500 rounded-full hover:bg-[#22213D]"
              onClick={() => carouselRef?.current?.previous()}
            >
              <svg
                className="h-5 w-5 md:h-6 md:w-6 text-[#cba660] group-hover:text-white"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.9999 12L4.99992 12M9.99992 6L4.70703 11.2929C4.3737 11.6262 4.20703 11.7929 4.20703 12C4.20703 12.2071 4.3737 12.3738 4.70703 12.7071L9.99992 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              id="slider-button-right"
              className="swiper-button-next group flex justify-center items-center border border-solid border-[#cba660] w-10 h-10 md:w-12 md:h-12 transition-all duration-500 rounded-full hover:bg-[#22213D]"
              onClick={() => carouselRef?.current?.next()}
            >
              <svg
                className="h-5 w-5 md:h-6 md:w-6 text-[#cba660] group-hover:text-white"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 12L19 12M14 18L19.2929 12.7071C19.6262 12.3738 19.7929 12.2071 19.7929 12C19.7929 11.7929 19.6262 11.6262 19.2929 11.2929L14 6"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </Zoom>
      </div>
      <Zoom direction="up">
        <Carousel
          ref={carouselRef}
          responsive={responsive}
          autoPlay
          autoPlaySpeed={3000}
          infinite
          arrows={false}
          showDots={false}
          className="z-0"
        >
          {testimonials.map((testimonial) => (
            <div className="testimonial-card px-2" key={testimonial.id}>
              <div className="group bg-white border border-solid h-auto border-gray-300 rounded-2xl p-6 transition-all duration-500 w-full">
                <div className="flex items-center mb-6 gap-2 text-[#cba660] transition-all duration-500">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      className="w-4 h-4 md:w-5 md:h-5"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                        fill="currentColor"
                      />
                    </svg>
                  ))}
                </div>
                <p className="text-sm md:text-lg font-light text-gray-500 sm:text-lg dark:text-gray-400 leading-6 md:leading-8 h-16 md:h-24 transition-all duration-500 mb-6 md:mb-9 group-hover:text-gray-800">
                  {testimonial[`content_${selectedLanguage}`]}
                </p>
                <div className="flex items-center gap-4 md:gap-5">
                  <img
                    src={`https://api.essenlux.ma/storage/${testimonial.image}`}
                    alt="avatar"
                    className="w-12 h-12 md:w-16 md:h-16 rounded-full"
                  />
                  <div className="grid gap-1">
                    <h5 className="text-gray-900 font-medium transition-all duration-500">
                      {testimonial[`client_name_${selectedLanguage}`]
                        ? testimonial[`client_name_${selectedLanguage}`]
                        : testimonial.client_name_en}
                    </h5>
                    <span className="text-xs md:text-sm leading-5 text-gray-500">
                      {testimonial[`role_${selectedLanguage}`]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </Zoom>
    </div>
  </section>
  );
};

export default Testimonials;
