import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaSearch } from "react-icons/fa";
import ProjectsSection from "../components/ProjectsSection";
import AboutSection from "../components/AboutEssenLux";
import EditorialSection from "../components/EditorialSection";
import PartnerSection from "../components/PartnerSection";
import { transport } from "../utils/TransportApi";
import { useNavigate } from "react-router-dom";
import { cities, responsive } from "../utils/Objects";
import { ProductsContext } from "../utils/context";
import Testimonial from "../components/Testimonial/Testimonial";
import FeatureSection from "../components/FeatureSection";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";

import "remixicon/fonts/remixicon.css";

const Home = () => {
  const [t] = useTranslation("global");
  const { subTypes } = useContext(ProductsContext);
  const [filters, setFilters] = useState({
    propertyType: "",
    city: "",
    rooms_number: "",
    status: "",
    minBudget: "",
    maxBudget: "",
  });

  const [carouselImages, setCarouselImages] = useState([]);
  const navigate = useNavigate();
  const { selectedLanguage } = useContext(ProductsContext);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Send filters to backend
    transport
      .post("/products/filter", filters)
      .then((response) => {
        // Handle response from backend
        navigate("/filter-results", { state: { data: response.data } });
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchCarouselImages();
  }, []);

  const fetchCarouselImages = () => {
    transport
      .get("/carousel-images")
      .then((response) => response.data)
      .then((data) => {
        setCarouselImages(data); // Update carousel images state
      })
      .catch((error) =>
        console.error("Error fetching carousel images:", error)
      );
  };

  return (
    <div>
      <Carousel
        responsive={responsive}
        autoPlay
        autoPlaySpeed={3000}
        infinite
        className="z-0"
      >
        {carouselImages && carouselImages.length > 0 ? (
          carouselImages.map((carouselImage) =>
            carouselImage.status ? (
              JSON.parse(carouselImage.image_bg).map((imageUrl, index) => (
                <div className="relative w-full h-[40vh] md:h-[75vh]" key={carouselImage.id + index}>
                <img
                  className="block w-full h-full object-cover"
                  src={`https://api.essenlux.ma/storage/${imageUrl}`}
                  alt="First slide"
                />
                <div className="absolute inset-x-0 bottom-[40%] flex flex-col items-center justify-center gap-2 px-4 py-2 text-white">
                  <span className="font-semibold text-2xl md:text-4xl lg:text-6xl text-[#CBA660] uppercase">
                    {carouselImage[`title_${selectedLanguage}`] || carouselImage.title_fr}
                  </span>
                  <span className="w-full md:w-[75%] text-center text-sm md:text-base lg:text-lg font-light">
                    {carouselImage[`description_${selectedLanguage}`] || carouselImage.description_fr}
                  </span>
                </div>
              </div>
              
              ))
            ) : (
              <div className="h-[40vh] md:h-[75vh] lg:h-[75vh] w-full">
                <img
                  className="d-block w-full rounded-3xl object-cover"
                  src={require("../assets/bgSection.webp")}
                  alt="Default"
                />
              </div>
            )
          )
        ) : (
          <div className="h-[40vh] md:h-[75vh] lg:h-[75vh] w-full">
            <img
              className="d-block w-full rounded-3xl object-cover"
              src={require("../assets/bgSection.webp")}
              alt="Default"
            />
          </div>
        )}
      </Carousel>
      {/* bar filter */}
      <div className="flex justify-center w-full items-center relative bottom-11">
        <div className="rounded-xl border max-w-screen-md border-gray-200 bg-white p-4 shadow-lg font-light">
          <form className="relative" onSubmit={handleSubmit}>
            <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
              <div className="flex flex-row gap-2">
                <div className="flex flex-col gap-6">
                  <select
                    onChange={handleInputChange}
                    name="propertyType"
                    className="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state-qu"
                  >
                    <option value="">{t("Type de propriété")}</option>
                    {subTypes.map((subType) => (
                      <option value={subType.name} key={subType.name}>
                        {subType.name}
                      </option>
                    ))}
                  </select>
                  <input
                    onChange={handleInputChange}
                    name="minBudget"
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    placeholder={t("Budget (min)")}
                  />
                </div>
                <div
                  className="vertical-line ml-2 hidden md:block"
                  style={{ borderLeft: "1px solid #ccc", height: "90%" }}
                ></div>
              </div>
              <div className="md:flex md:flex-row gap-2">
                <div className="flex flex-col gap-6">
                  <select
                    onChange={handleInputChange}
                    placeholder="Ville de Maroc"
                    name="city"
                    className="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value="">{t("Ville")}</option>
                    {cities.map((city) => (
                      <option value={city.value} key={city.value}>
                        {city.label}
                      </option>
                    ))}
                  </select>
                  <input
                    onChange={handleInputChange}
                    name="maxBudget"
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200  rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder={t("Budget (max)")}
                  />
                </div>
                <div
                  className="vertical-line ml-2 hidden md:block"
                  style={{ borderLeft: "1px solid #ccc", height: "90%" }}
                ></div>
              </div>
              <div className="flex flex-col gap-6">
                <input
                  onChange={handleInputChange}
                  name="rooms_number"
                  className="appearance-none block w-full rounded-lg bg-gray-50 text-gray-700 border border-gray-200 py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder={t("Chambres")}
                />
                <select
                  onChange={handleInputChange}
                  name="status"
                  className="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 py-3 px-4 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Status"
                >
                  <option value="">{t("Status")}</option>
                  <option value="disponible">{t("Disponible")}</option>
                  <option value="vendu">{t("Vendu")}</option>
                  <option value="loué">{t("Loué")}</option>
                </select>
              </div>
            </div>
            <div className="lg:mb-2 md:mb-2 mt-3 md:grid lg:flex lg:w-full md:grid-cols-2 lg:grid-cols-2 flex justify-center md:justify-end lg:justify-end space-x-4 relative">
              <button className="absolute lg:absolute flex flex-row items-center gap-2 active:scale-95 rounded-full bg-[#cba660] px-8 py-2 font-medium text-white outline-none focus:ring hover:bg-[#22213D]">
                <FaSearch />
                {t("Recherche")}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* projects section */}
      <ProjectsSection />

      {/* about section */}
      <AboutSection />

      {/* Feature section */}
      <FeatureSection />

      {/* Editorial section */}
      <EditorialSection />

      {/* Partners section */}
      <PartnerSection />

      {/* Avis section */}
      <Testimonial />

      <section
        className="w-screen bg-white bg-contain bg-center bg-no-repeat py-16 text-center font-medium text-gray-700 md:py-24"
        style={{
          backgroundImage:
            'url("https://sandbox.elemisthemes.com/assets/img/map.png")',
        }}
      >
        <div className="mx-auto w-full py-8 md:py-24">
          <div className="flex flex-wrap text-center justify-center">
            <div className="lg:w-1/2 w-full text-gray-700 xl:w-5/12">
              <div className="text-center">
                <Zoom direction="in">
                  <p className="text-[#CBA660] text-base block md:text-3xl lg:text-3xl font-bold">
                    {t(
                      "Choisissez ce que vous aimez, nous vous le fournissons"
                    )}
                  </p>
                </Zoom>
                <Zoom direction="in">
                  <a
                    href="/contact"
                    className="mt-8 md:mt-10 inline-flex items-center rounded-full bg-[#22213D] p-3 md:px-6 md:py-3 text-sm md:text-base font-medium text-[#CBA660] shadow-md transition-all hover:bg-[#CBA660] hover:text-white hover:translate-y-1 hover:scale-105 hover:shadow-lg"
                  >
                    {t("Contactez nous")}
                  </a>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
