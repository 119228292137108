import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { transport } from "../utils/TransportApi";
import Loader from "./Loader/Loader";
import ProductsComponent from "./ProductsComponent";
import Pagination from "./Pagination";
import { useTranslation } from "react-i18next";

const ProductsBySubtype = () => {
  const { type, subType } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("global");

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 6; // Number of products per page

  // Calculate the products to display on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(products.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await transport.get(
          `/products-by-subtype/${type}/${subType}`
        );
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [type, subType]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="products-container">
      <section
        className="bg-amber-50 py-20"
        style={{
          backgroundColor: "#22213D",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto">
          <div className="flex justify-center flex-col text-center items-center">
            <h1 className="font-bold text-3xl text-[#cba660] text-center uppercase">
              {subType}{" "}
              {type === "vente"
                ? t("à vendre")
                : type === "location"
                ? t("à louer")
                : t("Projet")}
            </h1>
            <div className="max-w-2xl mx-auto block mt-5 text-white">
              <p>
                {t("Parcourez notre catalogue des")} {subType}.{" "}
                {t("Découvrez une sélection exceptionnelle de")} {subType}{" "}
                {type === "vente"
                  ? t("à vendre")
                  : type === "location"
                  ? t("à louer")
                  : t("Projet")}
                !
              </p>
            </div>
          </div>
        </div>
      </section>
      {products.length === 0 ? (
        <div className="flex justify-center items-center h-64 my-4 ">
          <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
            <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
              <h2 className="mt-4 text-2xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                Aucun Résultat{" "}
              </h2>
              <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-base sm:leading-8">
                Aucun résultat ne correspond à votre requête. Si vous ne parvenez pas à
                trouver ce que vous cherchez, veuillez nous contacter en
                utilisant le formulaire de page Contact.{" "}
              </p>
            </div>
            <div className="mt-3 flex justify-center">
              <a
                href="/"
                className="text-sm font-semibold leading-6 text-primary"
              >
                <span aria-hidden="true">← </span>
                Retour à la page d'accueil{" "}
              </a>
            </div>
          </main>
        </div>
      ) : (
        <>
          <ProductsComponent numProducts={1000} products={currentProducts} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default ProductsBySubtype;
